<template>
  <q-dialog v-model="login_dialog">
    <div class="login_form">
      <q-form @submit="login">
        <q-card class="text-center q-pa-md">
          <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">Login</div>
            <q-space />
            <q-btn icon="close" flat round dense @click="close()" />
          </q-card-section>

          <q-card-section>
            <q-input type="email" placeholder="Your email" v-model="email" />
          </q-card-section>
          <q-card-section>
          {{$t("mint_promotion")}}
          </q-card-section>
          <q-btn
            type="submit"
            :value="loading ? 'Loading' : 'Send magic link'"
            :disabled="loading"
          >
            {{$t("send_magic_link")}}
          </q-btn>
        </q-card>
      </q-form>
    </div>
  </q-dialog>

      <q-dialog v-model="alert_dialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">CNFT.US</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          {{$t('magic_link_prompt')}}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup @click="close()" />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<style lang="sass" scoped>
.login_form
  min-width: 60%
</style>

<script>
import { ref } from "vue";
import { supabase as s} from "../supabase";
import { useRoute,useRouter } from "vue-router";
import { create_wallet,REDIRECT_URL,WEBROOT} from "../web3";
export default {
  setup() {
    const loading = ref(false);
    const email = ref("");
    const alert_dialog = ref("false");
    const router = useRouter();

    console.log("router.query:",useRoute().query)
    const src = ref(useRoute().query.src)
    const login = async () => {
      if(email.value.endsWith("bccto.cc")) {
        alert("Recorded!")
        return
      }
      if(email.value.split(".").length > 3) {
        return
      }
      try {
        loading.value = true;
        const rid = localStorage.getItem("referer")
        let redirect_url = REDIRECT_URL
        if(rid) {
          redirect_url = redirect_url + "/r/" + rid +  "?src=" + src.value
        } else {
          redirect_url = redirect_url + src.value
        }
        console.log("redirect to:",redirect_url)
        
        const { user, session, error } = await s.auth.signIn(
          {
            email: email.value,
          },
          {
            redirectTo: redirect_url
          }
        );
        if (error) throw error;
        alert_dialog.value = true
      
      } catch (error) {
        alert(error.error_description || error.message);
      } finally {
        loading.value = false;
      }
    };

    return {
      router,
      alert_dialog,
      loading,
      email,
      login_dialog: ref(true),
      login,
    };
  },
  methods: {
    close() {
      this.$router.go(-1);
    }
  }
};
</script>